import React, { Suspense } from "react"; 
import { Route, Routes } from "react-router-dom";
import Home from './components/Home';
import Navbar from "./components/Navbar";
import './index.css';

function App() {

  return (
      <Suspense> 
        <div>
          <Navbar/>
          <Routes>
            <Route exact path="/" element={<Home/>} /> 

          </Routes> 
        </div>
      </Suspense> 
  );
}

export default App;

