import React from 'react';
import styled from 'styled-components';

function Section(props){

    const {title, image, desc, desc2, link} = props.data

    return(
        <>
            <Container>
                <Top>
                    <VideoContainer>
                        <VCTop>
                            <Circle1/>
                            <Circle2/>
                            <Circle3/>
                        </VCTop>
                        <VCBottom>
                            <img src={image} alt="" />
                        </VCBottom>
                    </VideoContainer>
                </Top>
                <Bottom>
                    <Title>
                       {title}
                    </Title>
                    <Description>
                        {desc}
                        <br />
                        <p style={{marginTop:'1rem'}}>
                            {desc2}
                        </p> 
                    </Description>
                </Bottom>
                <Button>
                    <a href={link} target='_blank' rel="noreferrer">View in GitHub</a>
                </Button>
            </Container>
        </>
    )
}

export default Section;

const Container = styled.div`
    max-width: 640px;
    height: 100%;
    min-height: 720px;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    margin: auto;
    padding: 5rem 1rem;
`

const Top = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`



const Bottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
   
`

const Title = styled.div`
    margin-top: 2rem;
    font-size: 2.5rem;
    font-family: 'Oxygen';
    font-weight: 600;
`

const Description = styled.div`
    font-size: 1.25rem;
    margin-top: 1rem;
    line-height: 1.5; 
`

const Button = styled.div`
    display: flex;
    align-items: center;
    
    justify-content: center;
    margin-top: 1rem;   
    margin-bottom: 5rem;
    height: 50px;
    width: 240px;
    border-radius: 25px;
    display: flex;
    background: linear-gradient(to right, #FFC756, #FEA900);
    box-shadow: 0px 4px 8px rgba(255,182,34,0.3);
    &:hover{
        transform: scale(1.05);
        box-shadow: 0px 6px 12px rgba(255,182,34,0.4);
        transition: 0.5s ease;
        cursor: pointer;
    }
    &:active{
        background: linear-gradient(to left, #FFC756, #FEA900);
        transition: 0.5s ease;
    }
    & a{
        color: #000;
        font-weight: 500;
    }
`


const VideoContainer = styled.div`
    width: 100%;
    height: 360px;
    max-width: 640px;
    position: relative;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.15);
    border-radius:8px;
`
const VCTop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
    background: #1b1b1b;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px 8px 0 0;
`

const Circle1 = styled.div`
    position: relative;
    margin-left: 16px;
    height: 12px;
    width: 12px;
    background: #FF5656;
    border-radius: 50%;
`
const Circle2 = styled.div`
    position: relative;
    margin-left: 8px;
    height: 12px;
    width: 12px;
    background: #FFC756;
    border-radius: 50%;

`
const Circle3 = styled.div`
    position: relative;
    margin-left: 8px;
    height: 12px;
    width: 12px;
    background: #56FF6C;
    border-radius: 50%;

`

const VCBottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 330px;
    width: 100%;
    background: #000000;
    border-radius: 0px 0px 8px 8px;
    
    & img{
        object-fit: cover;
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 8px 8px;
    }
`