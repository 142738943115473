import React from 'react';
import styled from 'styled-components';
import logo from '../images/sd_logo.svg';
import '../index.css';

function Navbar(){
    return(
        <>
            <Container>
                <Placeholder>
                    <Logo>
                        <img src={logo} alt="" />
                    </Logo>
                    <Menu style={{display:'none'}}> 
                        <a href="/" className='active'>Work</a>
                        <a href="/" className=''>About</a>
                    </Menu>
                </Placeholder>
            </Container>
        </>
    )
}

export default Navbar;


const Container = styled.div`
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 80px;
    background: rgba(255,255,255,0);
    backdrop-filter: blur(10px) brightness(100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid #dbdbdb;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
`

const Placeholder = styled.div`
    width: 90%;
    max-width: 1024px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Logo = styled.div`
    width: 120px;
    & img{
        width: 96px;
    }
`

const Menu = styled.div`
    position: relative;
    font-size: 24px;
    padding: 0px 12px;

    & a{
        margin: 0px 24px;
        color: #000;
    }
`