import React from 'react';
import styled from 'styled-components';
import Section from './Section';
import '../index.css';  

function Home(){
    const secData = [
        {
            image: 'https://d39w1it2s36axc.cloudfront.net/srichand/csc8503.jpeg',
            title: 'Advanced Game Technologies - CSC8503',
            desc: 'A small racing game developed in C++ and OpenGL that included a combination of physics and AI.',
            desc2: 'Advanced features – Broad and narrow phase collision detection, Collision response methods, Linear & angular mechanics, and a Path-finding algorithm for AI.',
            link: 'https://github.com/ItachiUchiha619/Advanced-Game-Technologies-Master'
        },
        {
            image: 'https://d39w1it2s36axc.cloudfront.net/srichand/csc8502.jpeg',
            title: 'Advanced Graphics for Games - CSC8502',
            desc: 'A tropical island graphical scene within a forest using C++ and OpenGl.',
            desc2: 'Advanced Features – Framerate & memory management techniques, Multilayer texture rendering, Geometric shader programming for campfire, Post processing.',
            link: 'https://github.com/ItachiUchiha619/Advanced-Computer-Graphics'
        },
        {
            image: 'https://d39w1it2s36axc.cloudfront.net/srichand/csc8501.jpeg',
            title: 'Advanced Programming for Games - CSC8501 The Prize Maze',
            desc: 'A C++ OOP computer program to show how a maze can be traversed efficiently to collect prizes. The player starts with 150 points. It costs 1 health point for a player to move 1 square. Each Collected award is worth 11 health points.',
            desc2: 'Advanced C++ features – Threading, Memory management, Function pointers, Template functions, Polymorphism.',
            link: 'https://github.com/ItachiUchiha619/TripleX-game'
        }
        
    ]
    return(
        <>

            <HomeContainer>
                <FirstSection>
                    <MainSection>
                        <h1>
                        Loves to turn, <br />
                        anything into a game
                        </h1>
                    
                    </MainSection>
                    <GrainBackground/>
                    <BlurBackground/>
                    <Background>
                        <div className="circles">
                            <div className="circle-1"></div>
                            <div className="circle-2"></div>
                            <div className="circle-3"></div>
                        </div>
                    </Background>
                </FirstSection>
                
                {
                    secData.map(item => {
                        return (<Section data={item}/>)
                    })
                }

            </HomeContainer>

        </>
    );
}

export default Home;

const HomeContainer = styled.div`
    width: 100vw;
    overflow: hidden;
`

const FirstSection = styled.div`
    width: 100%;
    height: 100vh;
`

const Background = styled.div`
    opacity: 0.75;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    
    & .circle-1{
        position: absolute;
        top: 50%;
        transform: translateY(-60%);
        right: 45%;
        width: 20rem;
        height: 20rem;
        background: linear-gradient(to right, #7f00ff, #e100ff);
        border-radius: 50%;
    }
    & .circle-2{
        position: absolute;
        top: 50%;
        transform: translateY(-60%);
        left: 55%;
        width: 20rem;
        height: 20rem;
        background: linear-gradient(to right, #00a1ff, #00ff8f);
        border-radius: 50%;
    }
    & .circle-3{
        position: absolute;
        top: 50%;
        transform: translate(-50%,-10%);
        left: 50%;
        width: 20rem;
        height: 20rem;
        background: linear-gradient(to right, #fe8dc6, #fed1cf);
        border-radius: 50%;
    }
    
`

const GrainBackground = styled.div`
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background: url(https://grainy-gradients.vercel.app/noise.svg);
`


const BlurBackground = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(255,255,255,0);
    backdrop-filter: blur(50px) brightness(100%);
`

const MainSection = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    & h1{
        font-family: 'Slenco';
        font-size: 6rem ;
        @media screen and (max-width:720px){
            font-size: 3rem ;
    }
    }

    
`